.todo {
  background: #f3f3f3;
  border-radius: 0.5rem;
  margin: 1rem;
  transition: ease 0.2s;
  cursor: pointer;
}
.raise {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transform: rotate(5deg);
}
.todo:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.todoHead {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  margin: 0 0.5rem;
}
.title {
  padding: 5px;
}
.iconBox {
  display: flex;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 5px;
  transition: background ease 0.2s;
}
.icons:hover {
  background: rgba(255, 0, 0, 0.146);
}
.icons img {
  width: 1.3rem;
}
.green:hover {
  background: rgba(3, 182, 60, 0.146);
}
.desc {
  font-size: 1.4rem;
  margin: 0 1rem;
  line-height: 1.5rem;
  text-align: left;
  color: grey;
}
.todoFoot {
  display: flex;
  font-size: 1.2rem;
  width: 100%;
  justify-content: space-between;
}
.status {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  text-transform: capitalize;
}
.statusDot {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background: tomato;
  margin: 5px;
}
.date {
  color: grey;
  padding: 5px;
}
