.bar {
  display: flex;
  flex-direction: row;
  background: red;
  width: 100%;
}
.bar p {
  font-size: 1.4rem;
}
.barHolder {
  width: 100%;
  background: rgb(212, 212, 212);
  height: 1rem;
  border-radius: 0rem;
  overflow: hidden;
}
.barLen {
  background: #1b98f5;
  height: 100%;
  transition: width ease-in 0.4s;
}
