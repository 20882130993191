.sidebar {
  background: #1b98f5;
  width: 100%;
  height: 100%;
}
.logo {
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 2.5rem;
  height: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.logo span {
  color: #000;
}
.logoIcons {
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 3px;
}
.title {
  color: #fff;
  text-align: left;
  font-size: 1.5rem;
  padding: 0px 10px;
  height: 4%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.worklist {
  background: #157ccb;
  height: 78%;
  margin: 1rem;
  margin-top: 0rem;
  border-radius: 0.5rem;
  overflow: hidden;
  overflow-y: auto;
}
.workitem {
  transition: ease-in-out 0.2s;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  cursor: pointer;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.workitem.active {
  background: #fff;
}
.workitem:hover {
  background: #fff;
}
.buttonBox {
  height: 7%;
  margin: 0 1rem;
}
.button {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
}
.input {
  padding: 0 1rem;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: background ease 0.2s;
}
.icons:hover {
  background: rgba(255, 0, 0, 0.146);
}
.confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.61);
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}
.confirm span {
  display: flex;
  width: 30rem;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}
.confirm button {
  padding: 1rem;
  border: none;
  background: #1b98f5;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: ease-in 0.3s;
}
.confirm button:first-child {
  background: tomato;
}
.confirm p {
  font-weight: bold;
  font-size: 1.4rem;
}
.confirm button:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
@media only screen and (max-width: 600px) {
  .sidebar {
    position: absolute;
    display: flex;
    z-index: 9;
    height: 50px;
    bottom: 0px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .logo {
    display: none;
  }
  .title {
    display: flex;
    height: 90%;
    width: 30%;
  }
  .worklist {
    display: none;
  }
  .activeModal {
    /* display: none; */
    display: block;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9;
    height: calc(100% - 50px);
    border-radius: 0px;
  }
  .buttonBox {
    display: flex;
    height: 90%;
    justify-content: center;
    align-items: center;
    width: 70%;
  }
}
