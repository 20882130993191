.home {
  width: 100vw;
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.sidebar {
  width: 15%;
}
.mainbody {
  width: 85%;
}
@media only screen and (max-width: 600px) {
  .home {
    height: calc(100vh - 50px);
  }
  .sidebar {
    width: 0px;
    height: 50px;
  }
  .mainbody {
    width: 100%;
    height: calc(100% - 5px);
  }
}
