@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,700;1,400;1,700&display=swap');
*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.7rem;
  line-height: 1.7;
  margin: 0;
  background: #f2f3f4;
}
input,
button {
  font-family: 'Nunito Sans', sans-serif;
}
h1,
h2,
h3,
h4,
h5 {
  color: var(--color-primaryDark);
}

h1 {
  font-size: 7rem;
}
h2 {
  font-size: 4rem;
}
h3 {
  font-size: 3rem;
}
h4 {
  font-size: 2.5rem;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
/* Globas Css */
.card {
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.5rem;
}
