.body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}
.todos {
  width: 100%;
}
.activity {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  display: none;
}
.todoHead {
  height: 8%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 0rem;
}
.headDetails {
  display: flex;
  height: calc(100% - 1rem);
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.workTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 2rem;
  width: 250px;
}
.todoHead span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reset {
  background: #ff4444;
  padding: 5px 10px;
  margin: 0 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
/* ToDos BOdy */

.todoBody {
  height: 92%;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: scroll;
}
.newTodo {
  background: #1b98f5;
  color: #000;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  font-size: 1.5rem;
  margin-left: 10px;
  cursor: pointer;
  transition: ease-in 0.2s;
}

.newTodo:hover {
  background: #fff;
}
@media only screen and (min-width: 600px) {
  .reset::after {
    content: ' Workspace';
  }
}
