.todoList {
  margin: 0.5rem;
  margin-left: 0.5rem;

  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.5rem;
}
.listHead {
  display: flex;
  width: 25rem;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #1b98f5;
  margin: 0.5rem 1rem;
}
.title {
  color: #1b98f5;
  font-weight: bold;
  padding: 0.5rem;
}
.listBody {
  overflow: hidden;
  height: 90%;
  overflow-y: auto;
}
.toggle {
  background: red;
  color: red;
}
.colorDot {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  margin: 5px;
}
.colorDot::-webkit-color-swatch {
  border-radius: 50%;
  border: none;
}
.colorDot::-moz-color-swatch {
  border: none;
  border-radius: 50%;
}
.todoSkleton {
  background: #7d7d7d5f;
  border-radius: 0.5rem;
  margin-left: 10px;
  margin-right: 10px;
  height: 7rem;
  transition: all 0.3s ease-in;
}
