.btn {
  background: #1b98f5;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem;
  border-radius: 0.5rem;
  transition: ease 0.2s;
  cursor: pointer;
  transition: box-shadow ease 0.2s;
  padding: 5px;
  height: 4rem;
  min-width: 20rem;
}
.btn:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btn p {
  font-size: 1.4rem;
}
.btn img {
  width: 1.5rem;
}

.input img {
  width: 1.5rem;
}
.input input {
  width: 80%;
  border: none;
  padding: 5px;
}
.input input:focus {
  outline: none;
}
.input:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.list {
  height: 35px;
}
@media only screen and (max-width: 600px) {
}
